/**
 *  項目的配置文件
 */
//因為如果從common.js引入，而request又引入common, common也引入request。會出問題，所以直接在此定義getQueryVariable
const getQueryVariable = (variable) => {
     let query = window.location.search.substring(1);
     let vars = query.split("&");
     for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] == variable) {
               return pair[1];
          }
     }
     return false;
}

//確保第一時間獲取到 identity值，要不然打開則undefined
export const getIdentity = () => {
     if (localStorage.identity && localStorage.identity != 'false') {
          return localStorage.identity
     } else {
          if (getQueryVariable('storeId')) return 'seller'
          if (getQueryVariable('adminId')) return 'admin'
     }
}

export const identity = getIdentity()

let apiUrl,sourceUrl

if(getIdentity()=='seller'){
     apiUrl = `https://bbc-seller.qingqishow.com/`
     sourceUrl = `https://bbc-seller.qingqishow.com/`
}else if(getIdentity()=='admin'){
     apiUrl = `https://bbc-admin.qingqishow.com/`
     sourceUrl = `https://bbc-admin.qingqishow.com/`
}

export {apiUrl,sourceUrl}
export const chatUrl = 'wss://bbc-im.qingqishow.com'//客服地址
export const pcUrl = ''  //沒有PC終端，這裏無需配置，直接留空即可
export const curLang = 'zh';//當前語言,zh:中文，若為其他語言，需要對應/src/assets/language下面的文件名

/** copyright *** slodon *** version-v4.2.2 *** date-2023-03-22 ***主版本v4.6.2**/



